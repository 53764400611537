import { nanoid } from "nanoid";

const applicationList = {
  menuList: [
    // { id: nanoid(), title: "政策辅导", path: "https://zc.smesc.cn/", name: "PolicyView", target: true, unUse: true },
    { id: nanoid(), title: "智改数转", path: "/digitalize", name: "DigitalizeView", target: false },
    { id: nanoid(), title: "数据服务", path: "/servers", name: "ServersView", target: false },
    { id: nanoid(), title: "服务需求", path: "/demand", name: "DemandView", target: false },
    // { id: nanoid(), title: "热门培训", path: "/", name: "CultivateView", target: false, unUse: true },
    { id: nanoid(), title: "企服活动", path: "/activity", name: "ActivityView", target: false },
    // { id: nanoid(), title: "供需大厅", path: "/supplyDemand", name: "SupplyDemand", target: false },
  ],
  dropdownList: [
    { id: nanoid(), label: '个人中心', value: 'userCenter', path: '/userCenter', icon: 'fa-user-circle-o', divided: false, isUse: false },
    { id: nanoid(), label: '优惠券', value: 'coupon', path: '/coupon', icon: 'fa-ticket', divided: false, isUse: false },
    { id: nanoid(), label: '购物车', value: 'shopCart', path: '/shopCart', icon: 'fa-shopping-cart', divided: false, isUse: true },
    { id: nanoid(), label: '退出登录', value: 'logout', icon: 'fa-sign-out', divided: false, isUse: true }
  ],
  footerLink: [
    { id: nanoid(), name: '网站首页', path: '/', isUse: true },
    { id: nanoid(), name: '关于我们', path: '', isUse: false },
    { id: nanoid(), name: '联系方式', path: '', isUse: false },
    { id: nanoid(), name: '免责条款', path: '', isUse: false },
    { id: nanoid(), name: '版权隐私', path: '', isUse: false },
    { id: nanoid(), name: '网站地图', path: '', isUse: false },
    { id: nanoid(), name: '网站留言', path: '', isUse: false }
  ],
  bannerTab: [
    { id: nanoid(), title: '服务活动', value: 'activity', isUse: true },
    { id: nanoid(), title: '最新申报', value: 'zxtt', isUse: true },
    { id: nanoid(), title: '热门服务', value: 'servers', isUse: true },
    // { id: nanoid(), title: '明星服务商', value: 'provider', isUse: true },
    // { id: nanoid(), title: '平台公告', value: 'ptgg', isUse: false },
  ],
  /* 友情链接 */
  // 国家部委
  centralRange: [
    { id: nanoid(), value: 'samr', label: "国家市场监督管理总局", webset: 'https://www.samr.gov.cn/' },
    { id: nanoid(), value: 'mofcom', label: "中华人民共和国商务部", webset: 'http://www.mofcom.gov.cn/' },
    { id: nanoid(), value: 'ndrc', label: "国家发展和改革委员会", webset: 'https://www.ndrc.gov.cn/' },
    { id: nanoid(), value: 'most', label: "中华人民共和国科学技术部", webset: 'https://www.most.gov.cn/index.html' },
    { id: nanoid(), value: 'mof', label: "中华人民共和国财政部", webset: 'http://www.mof.gov.cn/index.htm' },
    { id: nanoid(), value: 'miit', label: "工业和信息化部", webset: 'https://www.miit.gov.cn/' },
  ],
  // 省厅局
  provinceRange: [
    { id: nanoid(), value: 'fgw', label: "四川省发展和改革委员会", webset: 'http://fgw.sc.gov.cn/' },
    { id: nanoid(), value: 'kjt', label: "四川省科学技术厅", webset: 'https://kjt.sc.gov.cn/' },
    { id: nanoid(), value: 'czt', label: "四川省财政厅", webset: 'http://czt.sc.gov.cn/' },
    { id: nanoid(), value: 'jxt', label: "四川省经济和信息化厅", webset: 'https://jxt.sc.gov.cn/' },
  ],
  // 行业协会
  industryRange: [],
  // 合作平台
  platformsRange: [
    { id: nanoid(), value: 'yiqicloud', label: "益企云", webset: 'https://www.yiqicloud.com.cn/#/' },
  ],
}

export default applicationList;